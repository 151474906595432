import React, { Component } from "react";
import Liveshow from "./../components/NewLiveShow/Liveshow";

class LiveShowScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      eId: props.match.params.id,
    };
  }

  render() {
    return <Liveshow eId={this.state.eId} />;
  }
}

export default LiveShowScreen;
