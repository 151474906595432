import React, { useEffect } from "react";
import { ErrorBoundary } from "react-error-boundary";
import "./App.css";
import Root from "./Root";
import Bugsnag from "@bugsnag/js";
import firebase from "firebase/app";

import { loadUser, logout } from "./actions/auth";

//Redux

import store from "./store";
import { auth } from "./utils/firebase";
import { Provider } from "./Provider";
import i18n from "./services/i18n";
import { Error } from "./screens/Error";

const App = () => {
  const resetAndLogout = (user) => {
    store.dispatch(logout(user), []);
  };
  useEffect(() => {
    auth().onAuthStateChanged((user) => {
      if (user) {
        store.dispatch(loadUser(user), []);
      } else {
        resetAndLogout(user);
      }
    });
  });

  const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

  return (
    <ErrorBoundary
      FallbackComponent={({ error, resetErrorBoundary }) => {
        return (
          <Error
            error={error}
            resetErrorBoundary={resetErrorBoundary}
            reset={resetAndLogout}
          />
        );
      }}
    >
      <Provider store={store}>
        <Root />
      </Provider>
    </ErrorBoundary>
  );
};

export default App;
