import React from "react";
import { useTranslation } from "react-i18next";
import Button from "../components/Button/Button";

export const Error = ({ error, resetErrorBoundary, reset }, props) => {
  const { t } = useTranslation();

  return (
    <div role="alert" id="error-page">
      <div className="error-container">
        <div className="error-content">
          <h1>{t("errorPage.title")}</h1>
          <h2> Error {error.code ?? "404"}</h2>
          <p>{t("errorPage.subtitle")}</p>

          <div className="button-container">
            <Button
              className="error-button"
              primary
              onClick={resetErrorBoundary}
            >
              {t("errorPage.tryAgain")}
            </Button>
            <Button
              className="error-button"
              outline
              color
              onClick={() => {
                resetErrorBoundary();
                reset();
              }}
            >
              {t("errorPage.backToHome")}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
