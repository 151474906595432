import React from "react";
import { Link } from "react-router-dom";
import { TextInput } from "../../components/TextInput.js/TextInput";
import Button from "../../components/Button/Button";
import { Divider } from "./../../components/Divider/Divider";
import styled from "styled-components";
import { useForm } from "react-hook-form";
import { setAlert } from "../../actions/alert";
import { register } from "../../actions/auth";
import { PropTypes } from "prop-types";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

const RightDiv = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 600px;
  margin: 0 auto;
`;

const Login = styled(Link)`
  margin: 15px 0;
`;

const Register = (props) => {
  const { t } = useTranslation();

  const { register, handleSubmit, watch, errors } = useForm();

  const onSubmit = async (data) => {
    var { email, name, password } = data;
    props.register({ email, name, password });
  };

  return (
    <div className="wrapper">
      <div className="content-without-nav">
        <div className="container">
          <div className="div-login"></div>
          <div className="login-card">
            <RightDiv>
              <h1 style={{ color: "#444" }}>{t("register.title")}</h1>
              <Divider
                height={"2px"}
                width={"50%"}
                primary
                marginVert={"20px"}
              />
              <form onSubmit={handleSubmit(onSubmit)}>
                <TextInput
                  name="name"
                  placeholder={t("register.name")}
                  stacked
                  ref={register({
                    required: true,
                  })}
                />
                {errors.name && (
                  <span className="input-error">
                    {t("register.requiredError")}
                  </span>
                )}

                <TextInput
                  name="email"
                  placeholder={t("register.email")}
                  stacked
                  ref={register({
                    required: true,
                    pattern: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                  })}
                />
                {errors.email && (
                  <span className="input-error">
                    {t("register.emailError")}
                  </span>
                )}

                <TextInput
                  name="password"
                  type="password"
                  placeholder={t("register.password")}
                  stacked
                  ref={register({
                    required: true,
                    minLength: 8,
                  })}
                />
                {errors.password && (
                  <span className="input-error">
                    {t("register.weakPasswordError")}
                  </span>
                )}

                <TextInput
                  name="password2"
                  type="password"
                  placeholder={t("register.passwordConfirm")}
                  stacked
                  ref={register({
                    required: true,
                    validate: (value) => {
                      return value === watch("password");
                    },
                  })}
                />
                {errors.password2 && (
                  <span className="input-error">
                    {t("register.passwordConfirmError")}
                  </span>
                )}

                <Button type="submit" primary loading={props.isLoading}>
                  {t("register.button")}
                </Button>
              </form>

              <Login to="/login">{t("register.alreadyAccount")}</Login>
            </RightDiv>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    isLoading: state.auth.progress,
  };
};

Register.propTypes = {
  setAlert: PropTypes.func.isRequired,
  register: PropTypes.func.isRequired,
};

export default connect(mapStateToProps, { setAlert, register })(Register);
