import React, { useState } from "react";
import Navbar from "./../components/Navbar/Navbar";
import { connect } from "react-redux";

import { Tab } from "semantic-ui-react";
import ProfilTab from "../components/Profil/ProfilTab";
import EditProfilTab from "../components/Profil/EditProfilTab";
import { useTranslation } from "react-i18next";

const Profil = (props) => {
  const { t } = useTranslation();
  var { user } = props;

  const [activeIndex, setIndex] = useState(0);

  const panes = [
    {
      menuItem: t("profil.profil"),
      render: () => <ProfilTab changeTab={handleTabChange} />,
    },
    {
      menuItem: t("profil.edit"),
      render: () => <EditProfilTab user={user} />,
    },
  ];

  const handleTabChange = (activeIndex) => {
    setIndex(activeIndex);
  };

  return (
    <div className="wrapper">
      <Navbar title={"Dein Profil"} />
      <div className={`content content-event`}>
        <div className={"container-profil"}>
          <Tab
            onTabChange={(e, { activeIndex }) => handleTabChange(activeIndex)}
            activeIndex={activeIndex}
            className={"tab-wrapper"}
            panes={panes}
            menu={{ secondary: true, pointing: true }}
          />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
});
export default connect(mapStateToProps, {})(Profil);
